body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.warning {
  color:red;
}

.control-table {
  width: 100%;
}

.brevet-card-input-km {
  width: 50px;
  background-color: rgb(254, 255, 186);
  border: 0;
  outline: 0;
  font-size: x-small;
  border-radius: 0;
  padding: 3px;
}

.brevet-card-input-place {
  width: 100px;
  background-color: rgb(254, 255, 186);
  border: 0;
  outline: 0;
  font-size: x-small;
  border-radius: 0;
  padding: 3px;
}

.brevet-card-table {
  color: black;
  border: 1px solid #e9e9e9; 
  border-collapse:collapse;
  text-align: left;
  vertical-align: text-top;
  font-size: 9pt;
}

.brevet-card-th {
  /* background-color: #fde400; */
  background-image: "stamps.png";
  color: black;
  border: 1px solid #e9e9e9;
  width: 100px;
  vertical-align: top;
  text-align: center;
}

.brevet-card-td {
  /* background-color: #fde400; */
  color: black;
  border: 1px solid #e9e9e9;
  width: 180px;
  height: 140px;
  vertical-align: top;
}

.background-table {
  position: relative;
}

.background-table::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(248, 241, 0, 0.967); /* Replace with your desired overlay color and opacity */
  z-index: -1;
}

.background-table::after {
  content: "";
  background-image: url('../public/stamps.png');
  background-repeat: repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -2;
}

.top-area {
  color: whitesmoke;
  background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
  padding: 40px 0;
  text-align: center;
  width: 100%;
  fill: whitesmoke;
}

.table-info {
  padding: 40px 0;
  text-align: center;
  width: 100%;
  font-size: x-small;
}

.bottom-area {
  color: whitesmoke;
  background-image: linear-gradient(180deg, rgb(5, 39, 103) 0%, #3a0647 70%);
  padding: 40px 0;
  text-align: center;
  width: 100%;
  fill: whitesmoke;
}

.long-distance-cycling-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.page-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.content-box {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 15px;
  padding: 20px;
  max-width: 100%;
}

.form-group {
  padding-right: 3%;
}

label {
  display: block;
  margin-bottom: 5px;
}

input,
textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  background-color: #ff0000;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #484848;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

